@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    @font-face {
        font-family: "Noto Sans Medium";
        src: url(/fonts/NotoSans-Medium.ttf) format("tff");
    }

    @font-face {
        font-family: "Anton-Regular";
        font-style: normal;
        font-weight: 900;
        font-display: optional;
        src: url(/fonts/Anton-Regular.ttf) format("tff");
    }

    @font-face {
        font-family: "Molle";
        src: url(/fonts/Molle-Italic.ttf) ;
    }
}

@layer components {

    .containerGrid, :after, :before {
        box-sizing: border-box;
        border: 0px solid #eb2598;
        border-top-width: 0px;
        border-right-width: 0px;
        border-bottom-width: 0px;
        border-left-width: 0px;
        border-top-style: solid;
        border-right-style: solid;
        border-bottom-style: solid;
        border-left-style: solid;
        border-top-color: rgb(235, 37, 152);
        border-right-color: rgb(235, 37, 152);
        border-bottom-color: rgb(235, 37, 152);
        border-left-color: rgb(235, 37, 152);
        border-image-source: initial;
        border-image-slice: initial;
        border-image-width: initial;
        border-image-outset: initial;
        border-image-repeat: initial;
    }

    .shadowedText {
        text-shadow: 0 0 8px rgb(0 0 0 / 80%);
    }

    .popOutBtn {
        position: relative;
        display: inline-block;
        background-color: #00FFD9;
    }

    .popOutBtn:before,
    .popOutBtn:after {
        content: "";
        display: block;
        background-color: #00FFD9;
        width: 8px;
        height: 8px;
        position: absolute;
        transition: all 0.15s ease;
    }

    .popOutBtn:before {
        top: 0;
        left: 0;
        transform-origin: top left;
        transform: rotate(-45deg) scale(0);
    }

    .popOutBtn:after {
        right: 0;
        bottom: 0;
        transform-origin: bottom right;
        transform: rotate(45deg) scale(0);
    }

    .popOutBtn__thumbnail {
        display: block;
        transform: translate(0, 0);
        transition: all 0.15s ease;
        position: relative;
        z-index: 10;
    }

    .popOutBtn:hover .popOutBtn__thumbnail {
        transform: translate(6px, -6px);
    }

    .popOutBtn:hover:before {
        transform: rotate(-45deg) scale(1);
    }

    .popOutBtn:hover:after {
        transform: rotate(45deg) scale(1);
    }

    #gradient-canvas {
        --gradient-color-1: #d86bd6;
        --gradient-color-2: #00FFD9;
        --gradient-color-3: #304e9f;
        --gradient-color-4: #b2bff3;
    }

    .text-balance {
        text-wrap: balance;
    }
}
